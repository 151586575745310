import $ from 'jquery'

const $doc = $(document)

function filterPosts() {
    const postsListWrapper = $('.posts__list-wrapper')
    const postsList = $('.posts__list')
    const resourcesIntro = $('.resources__intro')

    let state = {
      curr_page: 1,
      category: 'all',
      search: '',
      perPage: 6 // standardized posts_per_page count to reliably use paging
    }

    const category = $('.js-radio-category.is-active').attr('id');
    const search = $('#search-posts-keyword').val();
    const newQuery = ((state.category != category) || (state.search != search))
    const curr_page = newQuery ? 1 : state.curr_page + 1

    state.category = category
    state.search = search
    state.curr_page = curr_page

    console.log(category)
    console.log(curr_page)

    $.ajax({
        url : SITE.adminUrl,
        type : 'POST',
        data : {
            category,
            search,
            action:'ajaxfilter'
        },
        beforeSend : function(xhr){
            postsList.empty();
            resourcesIntro.empty();
            postsListWrapper.addClass('loading');
            $('.js-load-more-posts').hide();
        },
        success : function( res ){
            if (res.length) {
            
                if(res.includes('No posts found!')) {
                    $('.js-load-more-posts').hide();
                } else {
                    $('.js-load-more-posts').show();
                }

                postsList.html(res)
                postsListWrapper.removeClass('loading');
            } 
        }
    })
}

function loadMorePosts() {
    const postsListWrapper = $('.posts__list-wrapper')
    const postsList = $('.posts__list')

    let state = {
      curr_page: 1,
      category: 'all',
      search: '',
      perPage: 6 // standardized posts_per_page count to reliably use paging
    }

    const category = $('.js-radio-category.is-active').attr('id');
    const search = $('#search-posts-keyword').val();
    const curr_page = state.curr_page + 1

    state.category = category
    state.search = search
    state.curr_page = curr_page

    console.log(category)
    console.log(curr_page)

    $.ajax({
        url : SITE.adminUrl,
        type : 'POST',
        data : {
            category,
            curr_page,
            action:'loadmoreposts'
        },
        beforeSend : function(xhr){
            postsListWrapper.addClass('loading');
            $('.js-load-more-posts').hide();
        },
        success : function( res ){
          console.log(res);
            if (res.length) {
                if((res.match(/grid__column/) || []).length < state.perPage ) {
                    $('.js-load-more-posts').hide();
                } else {
                    $('.js-load-more-posts').show();
                }

                postsList.append(res)
                postsListWrapper.removeClass('loading');
            } 
        }
    })
}

export default function initFilters() {
    $doc.on('click','.js-filter-category-selected',function(event) {
      $(this).toggleClass('is-active')
      $('.js-filter-category-dropdown').toggleClass('is-active')
    })
    $doc.on('click', '.js-radio-category', function(event) {
        event.preventDefault();
        $('.js-radio-category').removeClass('is-active')
        $(this).addClass('is-active')
        let selected = $(this).data('name')
        $('.js-filter-category-dropdown').removeClass('is-active')
        $('.js-filter-category-selected').text(selected).removeClass('is-active')
        filterPosts() 
    })
    $doc.on('click', '.js-load-more-posts', loadMorePosts )

    $('#search-posts-keyword').keypress(function (event) {
        if (event.keyCode === 10 || event.keyCode === 13) {
            event.preventDefault();
        }
    });

    $("#search-posts-keyword").on('keyup', function (event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            filterPosts() 
        }
    });
}



