import $ from 'jquery'
const $doc = $(document)

function escapeFn(event) {
    var escUrl = $(this).attr('esc-url');
    // Open new window with specified URL
    location.replace(escUrl);
}

export default function initEscape() {
    $doc.on('click', '.js-escape-btn', escapeFn)
}
