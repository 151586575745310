import $ from 'jquery'
const $doc = $(document)

var $sidebar = $(".sidebar"),
    $window = $(window);

var isMobile = $(window).width() <= 1180;
var heroHeight = $('.hero').height() + $('.header').height()

function toggleActiveClass(){
    $(".sidebar a").removeClass('active');
    $(this).addClass('active');
}

function init() {
    $('.sidebar a').filter(function () {
        return this.href === location.href;
    }).addClass('active')
    toggleMobileClasses()
    // stickySidebar()

    $(window).resize(function () {
        heroHeight = $('.hero').height() + $('.header').height()
        toggleMobileClasses();
        // stickySidebar()
    });
}

function toggleMobileClasses(){
    isMobile = $(window).width() <= 1180;
    if (isMobile) {
        $('.sidebar__links a').addClass('mobile-links');
        $('.js-sidebar-nav').addClass('mobile-sidebar-nav');
        $(".mobile-sidebar-nav").removeClass('active');
        $(".sidebar__links").hide();
        // $sidebar.removeClass('fixed');
    }
    else{
        $('.sidebar__links a').removeClass('mobile-links');
        $('.js-sidebar-nav').removeClass('mobile-sidebar-nav');
        $(".mobile-sidebar-nav").removeClass('active');
        $(".sidebar__links").show();
        // stickySidebar()
    }
}

// function stickySidebar(){
//     isMobile = $(window).width() <= 1180

//     $window.scroll(function () {
//         if (!isMobile) {
//             if ($window.scrollTop() > heroHeight - 60) {
//                 console.log('scrolltop ' + $window.scrollTop() + ' and hero height ' + heroHeight);
//                 $sidebar.addClass('fixed')
//             }
//             else $sidebar.removeClass('fixed')
//         }
//         else $sidebar.removeClass('fixed')
//     });
// }

function openSidebarNav(){
    $(".sidebar__links").slideToggle('fast');
    $(this).toggleClass('active');
}

function closeSidebarNav(){
    $(".sidebar__links").slideToggle('fast')
    $(".mobile-sidebar-nav ").removeClass('active');
    console.log('clooose')
}

export default function initSidebar() {
    $(document).ready(init);
    $doc.on('click', '.js-sidebar-nav.mobile-sidebar-nav', openSidebarNav)
    $doc.on('click', '.sidebar__links .mobile-links', closeSidebarNav)
    $doc.on('click', '.sidebar a', toggleActiveClass)
}
