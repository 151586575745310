import Headroom from 'headroom.js'
import 'select2';

export default function initHeadroom(){
  const theHeader = document.querySelector('.js-header')
  const headroom = new Headroom(theHeader, { offset: 100, tolerance: 5 })
  headroom.init()
}

// custom nav dropdown
$(document).ready(function () {
  $('.js-nav-dropdown').select2({
    minimumResultsForSearch: -1,
    dropdownParent: $(".header__prenav__links")
  });
});